<app-breadcrumb></app-breadcrumb>
<app-no-product-found *ngIf="wishlistProducts.length == 0 && isLoading==false"></app-no-product-found>
<div class="wishlist-main-wrapper section-padding">
  <div class="container">
    <!-- Wishlist Page Content Start -->
    <div class="section-bg-color">
      <div class="row">
        <div class="col-lg-12">
          <!-- Wishlist Table Area -->
          <div class="cart-table table-responsive">
            <table class="table table-bordered" style="min-width: 820px">
              <thead>
              <tr *ngIf="wishlistProducts.length!=0">
                <th class="pro-title">Product</th>
                <th class="pro-price">Price</th>
                <th class="pro-quantity">Stock Status</th>
                <th class="pro-subtotal">Add to Cart</th>
                <th class="pro-remove">Remove</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let product of wishlistProducts;let i=index">
                <!--                <td class="pro-thumbnail">-->
                <!--                  <img [src]="product.product.base_image.small_image_url" alt="Product" class="img-fluid">-->
                <!--                </td>-->
                <td [routerLink]="[RouteConfig.productDetail,product.product.url_key]" class="w-100 C_P"
                    style="text-align: left">
                  <div class="d-flex justify-content-between align-items-start">
                    <div class="w-25">
                      <img [src]="product.product.base_image.small_image_url" alt="Product" class="img-fluid">
                    </div>
                    <div class="w-75 pl-1">
                      <p style="white-space: normal">
                        {{product.product.name|trim:50}}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="pro-price"><span>{{product.product.formated_price}}</span></td>
                <td class="pro-quantity">
                  <span *ngIf="product.product.in_stock" class="text-success">In Stock</span>
                  <span *ngIf="!product.product.in_stock" class="text-danger">Stock Out</span>
                </td>
                <td class="pro-subtotal">
                  <button (click)="addToCart(i)" [class.C_NA]="!product.product.in_stock"
                          [disabled]="!product.product.in_stock"
                          class="btn btn-sqr">
                    Add to Cart
                  </button>
                </td>
                <td class="pro-remove"><i (click)="removeFromWishlist(product.product.id)"
                                          class="fa fa-2x fa-trash-o C_P"></i>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Wishlist Page Content End -->
  </div>
</div>
