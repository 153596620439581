
<!-- testimonial area start -->
<section class="testimonial-area section-padding mt-20">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- section title start -->
        <div class="section-title text-center">
          <h2 class="title">Follow Us</h2>
          <p class="sub-title d-none">What they say</p>
        </div>
        <!-- section title start -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="d-flex mb-4">
          <img src="https://cdn4.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Facebook-512.png" class="img-thumbnail w_10" alt="facebook icon">
          <h4 class="text-uppercase ml-2 mt-auto text-body">Facebook</h4>
        </div>
        <div class="row">
<!--          <div class="bg-img col-11 mx-auto h_150" data-bg="assets/image/facebook-banner.jpg"></div>-->
          <img src="assets/image/facebook-banner.jpg" class="img-fluid" alt="facebook banner">
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex mb-4">
          <img src="https://www.instagram.com/static/images/ico/favicon-192.png/68d99ba29cc8.png" class="img-thumbnail w_10" alt="instagram icon">
          <h4 class="text-uppercase ml-2 mt-auto text-body">Instagram</h4>
        </div>
        <div class="row">
          <img class="col-md-3 col-sm-3 col-3 img-fluid mb-2" src="assets/image/insta-post-1.jpg" alt="insta post-1">
          <img class="col-md-3 col-sm-3 col-3 img-fluid mb-2" src="assets/image/insta-post-3.jpg" alt="insta post-2">
          <img class="col-md-3 col-sm-3 col-3 img-fluid mb-2" src="assets/image/insta-post-2.jpg" alt="insta post-3">
          <img class="col-md-3 col-sm-3 col-3 img-fluid mb-2" src="assets/image/insta-post-4.jpg" alt="insta post-4">
          <img class="col-md-3 col-sm-3 col-3 img-fluid mb-2" src="assets/image/insta-post-5.jpg" alt="insta post-5">
          <img class="col-md-3 col-sm-3 col-3 img-fluid mb-2" src="assets/image/insta-post-6.jpg" alt="insta post-6">
          <img class="col-md-3 col-sm-3 col-3 img-fluid mb-2" src="assets/image/insta-post-7.jpg" alt="insta post-7">
          <img class="col-md-3 col-sm-3 col-3 img-fluid mb-2" src="assets/image/insta-post-8.jpg" alt="insta post-8">
        </div>
      </div>
    </div>
  </div>
</section>
<!-- testimonial area end -->
