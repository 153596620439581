<div *ngIf="superAttribute.type=='select' && superAttribute.swatch_type==null" class="pro-size">
  <h6 class="option-title">{{superAttribute.name}} :</h6>
  <!--  <select [id]="'QV_'+superAttribute.id" class="nice-select">-->
  <!--    <option *ngFor="let option of superAttribute.options">{{option.label}}</option>-->
  <!--  </select>-->
  <select (change)="onChangeSelect($event)" [attr.data-value]="selectedValue" [value]="selectedValue"
          class="nice-select"
          nice-select>
    <option *ngFor="let option of superAttribute.options" [value]="option.id">{{option.label}}</option>
  </select>
</div>

<div *ngIf="superAttribute.type=='select' && superAttribute.swatch_type=='color'" class="color-option">
  <h6 class="option-title">{{superAttribute.name}} :</h6>
  <ul class="color-categories">
    <li *ngFor="let option of superAttribute.options" [class.color_option_border]="option.id == selectedColor">
      <a (click)="onChangeColor(option.id)"
         [title]="option.label"
         class="C_P"
         style="background-color:{{option.swatch_value}}"></a>
    </li>
  </ul>
</div>

<div *ngIf="superAttribute.type=='select' && superAttribute.swatch_type=='image'" class="color-option">
  <h6 class="option-title">{{superAttribute.name}} :</h6>
  <ul class="d-flex flex-wrap">
    <li *ngFor="let option of superAttribute.options"
        class="w-25 mr-2">
      <img (click)="onChangeImage(option.id)" [class.image_option_border]="option.id == selectedColor"
           [title]="option.label" alt=""
           class="img-fluid C_P img_hover"
           src="{{getImageUrl(option.swatch_value)}}"
           alt="image not found"
           >
    </li>
  </ul>
</div>
