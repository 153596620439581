<!-- product single item start -->
<ng-container>
  <!-- product item start -->
  <div class="product-item">
    <figure class="product-thumb">
      <a  href="javascript:void(0)" [routerLink]="[RouteConfig.productDetail, product.url_key]">
        <img
          [src]="product.base_image.original_image_url"
          alt="product"
          class="pri-img"
        />
        <img
          [src]="product.base_image.original_image_url"
          alt="product"
          class="sec-img"
        />
      </a>
      <div class="product-badge d-none">
        <div class="product-label new">
          <span>new</span>
        </div>
        <div class="product-label discount">
          <span>10%</span>
        </div>
      </div>
      <div class="button-group">
        <ng-container *ngIf="authService.isUserLogin">
          <a
          href="javascript:void(0)"
            (click)="addToWishlist(product.id)"
            class="C_P"
            data-placement="left"
            data-toggle="tooltip"
            [title]="wishListMsg"
            ><i class="pe-7s-like"></i
          ></a>
        </ng-container>
        <a href="javascript:void(0)" (click)="showQuickViewModal()" class="d-none"
          ><span
            class="C_P"
            data-placement="left"
            data-toggle="tooltip"
            title="Quick View"
            ><i class="pe-7s-search"></i></span
        ></a>
      </div>
      <div class="cart-hover">
        <button
          (click)="addToCart()"
          *ngIf="product.in_stock"
          class="btn btn-cart d-none"
        >
          add to cart
        </button>
      </div>
    </figure>
    <div class="product-caption text-center">
      <div class="product-identity">
        <p class="manufacturer-name d-none">
          <a href="javascript:void(0)" [routerLink]="[RouteConfig.productDetail, product.url_key]"
            >Gold</a
          >
        </p>
      </div>
      <ul class="color-categories d-none">
        <li>
          <a class="c-lightblue" href="javascript:void(0)" title="LightSteelblue"></a>
        </li>
        <li>
          <a class="c-darktan" href="javascript:void(0)" title="Darktan"></a>
        </li>
        <li>
          <a class="c-grey" href="javascript:void(0)" title="Grey"></a>
        </li>
        <li>
          <a class="c-brown" href="javascript:void(0)" title="Brown"></a>
        </li>
      </ul>
      <h6 class="product-name">
        <a href="javascript:void(0)" [routerLink]="[RouteConfig.productDetail, product.url_key]">{{
          product.name | trim: 50
        }}</a>
      </h6>
      <div class="price-box">
        <span class="price-regular">{{ product.formated_price }}</span>
        <span class="price-old d-none"><del>$70.00</del></span>
      </div>
    </div>
  </div>
  <!-- product item end -->
  <div class="product-list-item">
    <figure class="product-thumb">
      <a href="javascript:void(0)" [routerLink]="[RouteConfig.productDetail, product.url_key]">
        <img
          [src]="product.base_image.small_image_url"
          alt="product"
          class="pri-img"
        />
        <img
          [src]="product.base_image.small_image_url"
          alt="product"
          class="sec-img"
        />
      </a>
      <div class="product-badge d-none">
        <div class="product-label new">
          <span>new</span>
        </div>
        <div class="product-label discount">
          <span>10%</span>
        </div>
      </div>
      <div class="button-group">
        <ng-container *ngIf="authService.isUserLogin">
          <a
          href="javascript:void(0)"
            (click)="addToWishlist(product.id)"
            class="C_P"
            data-placement="left"
            data-toggle="tooltip"
            [title]="wishListMsg"
            ><i class="pe-7s-like"></i
          ></a>
        </ng-container>
        <a href="javascript:void(0)" (click)="showQuickViewModal()" class="d-none"
          ><span
            class="C_P"
            data-placement="left"
            data-toggle="tooltip"
            title="Quick View"
            ><i class="pe-7s-search"></i></span
        ></a>
      </div>
      <div class="cart-hover">
        <button
          (click)="addToCart()"
          *ngIf="product.in_stock"
          class="btn btn-cart d-none"
        >
          add to cart
        </button>
      </div>
    </figure>
    <div class="product-content-list">
      <div class="manufacturer-name d-none">
        <a  href="javascript:void(0)" [routerLink]="[RouteConfig.productDetail, product.url_key]"
          >Platinum</a
        >
      </div>
      <ul class="color-categories d-none">
        <li>
          <a class="c-lightblue" href="javascript:void(0)" title="LightSteelblue"></a>
        </li>
        <li>
          <a class="c-darktan" href="javascript:void(0)" title="Darktan"></a>
        </li>
        <li>
          <a class="c-grey" href="javascript:void(0)" title="Grey"></a>
        </li>
        <li>
          <a class="c-brown" href="javascript:void(0)" title="Brown"></a>
        </li>
      </ul>

      <h5 class="product-name">
        <a  href="javascript:void(0)" [routerLink]="[RouteConfig.productDetail, product.url_key]">{{
          product.name
        }}</a>
      </h5>
      <div class="price-box">
        <span class="price-regular">{{ product.formated_price }}</span>
        <span class="price-old d-none"><del>$29.99</del></span>
      </div>
      <p [innerHTML]="product.short_description"></p>
    </div>
  </div>
</ng-container>
<!-- product single item start -->
