<!--desktop start-->
<div
  class="row d-none d-md-flex"
  *ngIf="productImage && productImage?.length > 0"
>
  <div class="col-2" style="min-height: 400px">
    <div class="pro-nav-desktop slick-row-11 slick-arrow-style mt-0">
      <div class="pro-nav-thumb my-1" *ngFor="let image of productImage">

        <img [src]="image.small_image_url" alt="product-details" />
      </div>
    </div>
  </div>
  <div class="col-9">
    <div class="product-large-slider-desktop">
      <!-- <div class="pro-large-img img-zoom" style="min-height: 400px;" *ngFor="let image of productImage"> -->
      <div class="pro-large-img image-container" style="min-height: 400px;" *ngFor="let image of productImage; let i = index">
        <!-- <img
          [src]="image.large_image_url"
          alt="product-details"
          #desktopImgList
        /> -->
        <div class="main-image-container" (mousemove)="onMouseMove($event, i)" (mouseleave)="onMouseLeave(i)">
          <img [src]="image?.large_image_url" class="main-image">
          <div class="lens" #lens></div>
        </div>
        <div class="zoom-image" #zoomImage [style.backgroundImage]="'url(' + image?.large_image_url + ')'"></div>

        <!-- <div class="position-relative" #desktopImgList *ngIf="image.large_image_url">

          <ngx-img-zoom
              [zoomImageSrc]="image.large_image_url"
              [previewImageSrc]="image.large_image_url"
              [imgStyle]="'height:400px;  bottom:0;  left:0;  right:0;  top:0;  margin:0 auto; border:1px solid #dcdcdc;'"
              [resultStyle]="'width:500px; height:500px; z-index: 9999; position:absolute;box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); top: 0;left: 100%;'"
              [lensStyle]="'width:30px; height:30px'"
              [enableZoom]="true"
            [containerStyle]=""
            ></ngx-img-zoom>
        </div> -->
      </div>
    </div>
  </div>
</div>

<!-- mobile start-->
<div
  (click)="toggleModal()"
  class="product-large-slider-mobile d-md-none"
  *ngIf="productImage && productImage?.length > 0"
>
  <div class="pro-large-img" *ngFor="let image of productImage">
    <img [src]="image.large_image_url" alt="product-details" />
  </div>
</div>
<div
  class="pro-nav-mobile slick-row-10 slick-arrow-style d-md-none mt-3"
  *ngIf="productImage && productImage?.length > 0"
>
  <div class="pro-nav-thumb" *ngFor="let image of productImage">
    <img [src]="image.small_image_url" alt="product-details" #mobileImgList />
  </div>
</div>

<!-- Modal for mobile -->

<div *ngIf="isShowModal" class="modal-fade-in vh-100">
  <div class="modal-dialog">
    <div class="modal-content">
      <div
        data-dismiss="modal"
        class="ic-close-quickview"
        (click)="toggleModal()"
      >
        <i class="fa fa-close"></i>
      </div>
      <div
        class="product-large-slider-mobile-popup d-md-none"
        *ngIf="productImage && productImage?.length > 0"
      >
        <div class="pro-large-img" *ngFor="let image of productImage">
          <img [src]="image.large_image_url" alt="product-details" />
        </div>
      </div>
      <div
        class="
          pro-nav-mobile-popup
          slick-row-10 slick-arrow-style
          d-md-none
          mt-3
        "
        *ngIf="productImage && productImage?.length > 0"
      >
        <div class="pro-nav-thumb" *ngFor="let image of productImage">
          <img
            [src]="image.small_image_url"
            alt="product-details"
            #mobileImgList
          />
        </div>
      </div>
    </div>
  </div>
</div>
