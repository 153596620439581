<div class="checkout-billing-details-wrap">
  <h5 class="checkout-title">Billing Details</h5>
  <div class="billing-form-wrap">
    <form action="#">
      <div class="row">
        <div class="col-md-6">
          <div class="single-input-item">
            <label class="required" for="f_name">First Name</label>
            <input id="f_name" placeholder="First Name" required type="text"/>
          </div>
        </div>

        <div class="col-md-6">
          <div class="single-input-item">
            <label class="required" for="l_name">Last Name</label>
            <input id="l_name" placeholder="Last Name" required type="text"/>
          </div>
        </div>
      </div>

      <div class="single-input-item">
        <label class="required" for="email">Email Address</label>
        <input id="email" placeholder="Email Address" required type="email"/>
      </div>

      <div class="single-input-item">
        <label for="com-name">Company Name</label>
        <input id="com-name" placeholder="Company Name" type="text"/>
      </div>

      <div class="single-input-item">
        <label class="required" for="country">Country</label>
        <select id="country" name="country nice-select">
          <option value="Afghanistan">Afghanistan</option>
          <option value="Albania">Albania</option>
          <option value="Algeria">Algeria</option>
          <option value="Armenia">Armenia</option>
          <option value="Bangladesh">Bangladesh</option>
          <option value="India">India</option>
          <option value="Pakistan">Pakistan</option>
          <option value="England">England</option>
          <option value="London">London</option>
          <option value="London">London</option>
          <option value="Chaina">China</option>
        </select>
      </div>

      <div class="single-input-item">
        <label class="required mt-20" for="street-address">Street address</label>
        <input id="street-address" placeholder="Street address Line 1" required
               type="text"/>
      </div>

      <div class="single-input-item">
        <input placeholder="Street address Line 2 (Optional)" type="text"/>
      </div>

      <div class="single-input-item">
        <label class="required" for="town">Town / City</label>
        <input id="town" placeholder="Town / City" required type="text"/>
      </div>

      <div class="single-input-item">
        <label for="state">State / Divition</label>
        <input id="state" placeholder="State / Divition" type="text"/>
      </div>

      <div class="single-input-item">
        <label class="required" for="postcode">Postcode / ZIP</label>
        <input id="postcode" placeholder="Postcode / ZIP" required type="text"/>
      </div>

      <div class="single-input-item">
        <label class="required" for="phone">Phone</label>
        <input id="phone" placeholder="Phone" type="text"/>
      </div>

      <div class="checkout-box-wrap">
        <div class="single-input-item">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" id="create_pwd" type="checkbox">
            <label class="custom-control-label" for="create_pwd">Create an
              account?</label>
          </div>
        </div>
        <div class="account-create single-form-row">
          <p>Create an account by entering the information below. If you are a
            returning customer please login at the top of the page.</p>
          <div class="single-input-item">
            <label class="required" for="pwd">Account Password</label>
            <input id="pwd" placeholder="Account Password" required
                   type="password"/>
          </div>
        </div>
      </div>

      <div class="checkout-box-wrap">
        <div class="single-input-item">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" id="ship_to_different"
                   type="checkbox">
            <label class="custom-control-label" for="ship_to_different">Ship to a
              different address?</label>
          </div>
        </div>
        <div class="ship-to-different single-form-row">
          <div class="row">
            <div class="col-md-6">
              <div class="single-input-item">
                <label class="required" for="f_name_2">First Name</label>
                <input id="f_name_2" placeholder="First Name" required
                       type="text"/>
              </div>
            </div>

            <div class="col-md-6">
              <div class="single-input-item">
                <label class="required" for="l_name_2">Last Name</label>
                <input id="l_name_2" placeholder="Last Name" required
                       type="text"/>
              </div>
            </div>
          </div>

          <div class="single-input-item">
            <label class="required" for="email_2">Email Address</label>
            <input id="email_2" placeholder="Email Address" required type="email"/>
          </div>

          <div class="single-input-item">
            <label for="com-name_2">Company Name</label>
            <input id="com-name_2" placeholder="Company Name" type="text"/>
          </div>

          <div class="single-input-item">
            <label class="required" for="country_2">Country</label>
            <select id="country_2" name="country">
              <option value="Bangladesh">Bangladesh</option>
              <option value="India">India</option>
              <option value="Pakistan">Pakistan</option>
              <option value="England">England</option>
              <option value="London">London</option>
              <option value="London">London</option>
              <option value="Chaina">Chaina</option>
            </select>
          </div>

          <div class="single-input-item">
            <label class="required mt-20" for="street-address_2">Street
              address</label>
            <input id="street-address_2" placeholder="Street address Line 1"
                   required type="text"/>
          </div>

          <div class="single-input-item">
            <input placeholder="Street address Line 2 (Optional)" type="text"/>
          </div>

          <div class="single-input-item">
            <label class="required" for="town_2">Town / City</label>
            <input id="town_2" placeholder="Town / City" required type="text"/>
          </div>

          <div class="single-input-item">
            <label for="state_2">State / Divition</label>
            <input id="state_2" placeholder="State / Divition" type="text"/>
          </div>

          <div class="single-input-item">
            <label class="required" for="postcode_2">Postcode / ZIP</label>
            <input id="postcode_2" placeholder="Postcode / ZIP" required
                   type="text"/>
          </div>
        </div>
      </div>

      <div class="single-input-item">
        <label for="ordernote">Order Note</label>
        <textarea cols="30" id="ordernote" name="ordernote"
                  placeholder="Notes about your order, e.g. special notes for delivery."
                  rows="3"></textarea>
      </div>
    </form>
  </div>
</div>
