<address>
  <span><strong class="text-capitalize">{{address.first_name}} {{address.last_name}}</strong></span>
  <br>
  <span><strong>City:</strong> {{address.city}}</span>,
  <br>
  <span><strong>State:</strong> {{address.state}}</span>,
  <br>
  <span><strong>Post Code:</strong> {{address.postcode}}</span>,
  <br>
  <span><strong>Country:</strong>{{address.country_name}}</span>
</address>
