<!-- breadcrumb area start -->
<div class="breadcrumb-area">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb-wrap">
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a [routerLink]="RouteConfig.home"
                  ><i class="fa fa-home"></i
                ></a>
              </li>
              <li
                *ngFor="let url of current?.urls"
                [class.active]="url.current"
                aria-current="page"
                class="breadcrumb-item"
              >
                <ng-container *ngIf="url.current">
                  {{ url.name }}
                </ng-container>
                <a *ngIf="!url.current" [routerLink]="url.link">{{
                  url.name
                }}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb area end -->
