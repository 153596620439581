<!-- hero slider area start -->
<div class="container">
  <div class="d-flex justify-content-center align-content-center">
    <div *ngIf="loading">
      <div class="spinner-border" role="status">
          <span class="sr-only" id="loading"></span>
      </div>
    </div>
  </div>
</div>
<section class="slider-area" *ngIf="!loading">
  <div
    class="
      hero-slider-active
      slick-arrow-style slick-arrow-style_hero
      slick-dot-style
    "
    #divRef
  >
    <!-- single slider item start -->
    <div
      class="hero-single-slide hero-overlay"
      *ngFor="let slider of sliderList"
    >
      <div
        class="hero-slider-item bg-img"
        style="background-image: url('{{ slider.image_url }}'); cursor: {{ slider?.path ?  'pointer' : 'auto' }}"
        (click)="gotoLink(slider)"
      >
        <div class="container">
          <div class="row">
            <p class="d-none">{{ slider.image_url }}</p>
            <div class="col-md-12" [innerHTML]="slider.content"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- single slider item start -->
  </div>
</section>
<div class="spacing"></div>
<!-- hero slider area end -->
