
<div class="container">
  <div class="d-flex justify-content-center align-content-center">
    <div *ngIf="loading">
      <div class="spinner-border" role="status">
          <span class="sr-only" id="loading"></span>
      </div>
    </div>
  </div>
</div>
<div class="banner-statistics-area" *ngIf="!loading">
  <div class="container">
    <div class="row row-20" *ngIf="posterList?.length > 1">
      <div class="col-sm-6" *ngFor="let poster of posterList; let i = index">
        <figure class="banner-statistics mt-20">
          <a href="javascript:void(0)" (click)="goToLink(poster)" class="C_P">
            <img [src]="poster?.image_url" [alt]="poster?.title" />
          </a>
          <!--          <div class="banner-content text-right d-none">-->
          <!--            <h5 class="banner-text1">BEAUTIFUL</h5>-->
          <!--            <h2 class="banner-text2">Wedding<span>Rings</span></h2>-->
          <!--            <a href="shop.html" class="btn btn-text">Shop Now</a>-->
          <!--          </div>-->
        </figure>
      </div>
    </div>
  </div>
</div>
<div class="spacing"></div>
