import { Component, OnInit } from '@angular/core';
import { Collection } from 'src/app/modals/collection.modal';
import { HomeGeneralService } from '../../services/home/home-general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit {

  loading = true;
  collectionList: Collection[] = [];

  constructor(private homeGeneralService: HomeGeneralService, private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    try {
      const res = await this.homeGeneralService.getCollectionListing();
      this.collectionList = res.data;
      this.loading = false;
    } catch (e) {
      console.error('::::AAA::::BBB::::', e);
    }
  }

  gotoCollection(data: any) {

    if (data && data.path) {
      if (data.path.includes('https://xyraa.com')) {
      let path = new URL(data.path).pathname;
      return path;
      this.gotoLink(path)
    }
    window.open(data.path, '_blank');
    }
    return ""
  }
  gotoLink(link: any) {
    this.router.navigateByUrl(link)
  }
}
