import {
  AfterContentChecked,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Image } from '../../modals/image.modal';
import { HomeGeneralService } from '../../services/home/home-general.service';
import { JsLoader } from '../../shared/static/js-loader';
import { EventBusEmitService } from '@shared/service/event-bus-emit.service';
import { Router } from '@angular/router';

declare var $;

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss'],
})
export class HomeSliderComponent implements OnInit, AfterContentChecked {
  @ViewChild('divRef') ref;
  loading = true;
  sliderList: Image[];

  constructor(
    private homeGeneralService: HomeGeneralService,
    private router: Router,
    private eventBusEmitService: EventBusEmitService
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      const res = await this.homeGeneralService.getSliderListing();
      this.sliderList = res.data;
      this.loading = false;
      setTimeout(() => {
        JsLoader.loadHomeSliderJs(this.ref?.nativeElement);
        this.eventBusEmitService.showFooter();
      }, 10);
    } catch (e) {
      console.error('::::AAA::::BBB::::', e);
    }
  }
  gotoLink(data: any) {
    if (data.path) {
      try {
        console.log(data.path);

        const isValidUrl = (url: string) => {
          try {
            return new URL(url).protocol === 'https:';
          } catch {
            return false;
          }
        };

        if (data.path.startsWith('https://') && !data.path.includes('xyraa.com') && isValidUrl(data.path)) {
          window.open(data.path, '_blank');
        } else if (!data.path.startsWith('http') || data.path.includes('xyraa.com')) {
          this.gotoUrl(data.path);
        } else {
          console.error('Invalid URL:', data.path);
        }
      } catch (err) {
        console.error('Error processing URL:', err);
      }
    }
  }
  gotoUrl(link: any) {
    this.router.navigateByUrl(link)
  }
  ngAfterContentChecked(): void { }

  // loadJsForBgImage():void{
  //   // Background Image JS start
  //   var bgSelector = $(".bg-img");
  //   bgSelector.each(function (index, elem) {
  //     var element = $(elem),
  //       bgSource = element.data('bg');
  //     element.css('background-image', 'url(' + bgSource + ')');
  //   });
  // }
}
