<!-- Start Header Area -->
<header class="header-area header-wide">


    <div class="mobile-header d-lg-none d-md-block sticky">
        <!-- header top start -->
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="mobile-main-header">
                        <div class="mobile-logo">
                            <a href="index.html">
                                <img src="assets/theme/img/logo/logo.png" alt="Brand Logo">
                            </a>
                        </div>
                        <div class="mobile-menu-toggler">
                            <div class="mini-cart-wrap">
                                <a href="cart.html">
                                    <i class="pe-7s-shopbag"></i>
                                    <div class="notification">0</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- header top start -->
    </div>
</header>
<!-- end Header Area -->
<main>
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-wrap">
                        <nav aria-label="breadcrumb">
                            <ul class="breadcrumb">
                                <li class="breadcrumb-item"><a href="index.html"><i class="fa fa-home"></i></a></li>
                                <li class="breadcrumb-item"><a href="blog-left-sidebar.html">blog</a></li>
                                <li class="breadcrumb-item active" aria-current="page">blog details right sidebar
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- breadcrumb area end -->

    <!-- blog main wrapper start -->
    <div class="blog-main-wrapper section-padding">
        <div class="container">
            <div class="row">

                <div class="col-lg-9 order-1">
                    <div class="blog-item-wrapper">
                        <!-- blog post item start -->
                        <div class="blog-post-item blog-details-post">
                            <figure class="blog-thumb">
                                <div class="blog-carousel-2 slick-row-15 slick-arrow-style slick-dot-style">
                                    <div class="blog-single-slide">
                                        <img src={{imageUrl}} alt="blog image">
                                    </div>
                                </div>
                            </figure>
                            <div class="blog-content">
                                <h3 class="blog-title">
                                   {{title}}
                                </h3>
                                <div class="blog-meta">
                                    <p>25/03/2019 | <a href="javascript:void(0)">Corano</a></p>
                                </div>
                                <div class="entry-summary">
                                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate
                                        perferendis
                                        consequuntur illo aliquid nihil ad neque, debitis praesentium libero ullam
                                        asperiores exercitationem deserunt inventore facilis, officiis,</p>
                                    <blockquote>
                                        <p>Quisque semper nunc vitae erat pellentesque, ac placerat arcu
                                            consectetur.
                                            venenatis elit ac ultrices convallis. Duis est nisi, tincidunt ac urna
                                            sed,
                                            cursus blandit lectus. In ullamcorper sit amet ligula ut eleifend. Proin
                                            dictum
                                            tempor ligula, ac feugiat metus. Sed finibus tortor eu scelerisque
                                            scelerisque.</p>
                                    </blockquote>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- blog main wrapper end -->
</main>


