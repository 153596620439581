<section class="review-area section-padding bg-img" data-bg="assets/img/review/reviews-bg.jpg">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- section title start -->
                <div class="section-title text-center">
                    <h2 class="title">OUR CUSTOMER LOVE US</h2>
                    <hr class="line">
                    <h3 class="margin-t">50K+</h3>
                    <div class="review-star">
                        <span><i class="fa fa-star"></i></span>
                        <span><i class="fa fa-star"></i></span>
                        <span><i class="fa fa-star"></i></span>
                        <span><i class="fa fa-star"></i></span>
                        <span><i class="fa fa-star"></i></span>
                    </div>
                    <h3>REVIEWS</h3>
                </div>
                <!-- section title start -->
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="review-content-wrapper">
                    <div class="review-content-carousel">
                        <div class="review-content">
                            <p>Vivamus a lobortis ipsum, vel condimentum magna. Etiam id turpis tortor. Nunc
                                scelerisque, nisi a blandit varius, nunc purus venenatis ligula, sed venenatis
                                orci augue nec sapien. Cum sociis natoque</p>
                            <h5 class="review-author">lindsy niloms</h5>
                        </div>
                        <div class="review-content">
                            <p>Vivamus a lobortis ipsum, vel condimentum magna. Etiam id turpis tortor. Nunc
                                scelerisque, nisi a blandit varius, nunc purus venenatis ligula, sed venenatis
                                orci augue nec sapien. Cum sociis natoque</p>
                            <h5 class="review-author">Daisy Millan</h5>
                        </div>
                        <div class="review-content">
                            <p>Vivamus a lobortis ipsum, vel condimentum magna. Etiam id turpis tortor. Nunc
                                scelerisque, nisi a blandit varius, nunc purus venenatis ligula, sed venenatis
                                orci augue nec sapien. Cum sociis natoque</p>
                           <h5 class="review-author">Anamika lusy</h5>
                        </div>
                        <div class="review-content">
                            <p>Vivamus a lobortis ipsum, vel condimentum magna. Etiam id turpis tortor. Nunc
                                scelerisque, nisi a blandit varius, nunc purus venenatis ligula, sed venenatis
                                orci augue nec sapien. Cum sociis natoque</p>
                            <h5 class="review-author">Maria Mora</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="spacing"></div>
