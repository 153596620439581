
<div class="container">
  <div class="d-flex justify-content-center align-content-center">
    <div *ngIf="loading">
      <div class="spinner-border" role="status">
          <span class="sr-only" id="loading"></span>
      </div>
    </div>
  </div>
</div>
<div class="banner-statistics-area" *ngIf="!loading">
  <div class="container">
    <div class="row row-20 mtn-20">
      <div class="col-12">
        <figure class="banner-statistics mt-20" *ngIf="posterList?.length > 0">
          <a (click)="goToLink(posterList[0])" class="C_P">
            <img
              [src]="posterList[0]?.image_url"
              [alt]="posterList[0]?.title"
            />
          </a>
          <!--          <div class="banner-content text-right d-none">-->
          <!--            <h5 class="banner-text1">BEAUTIFUL</h5>-->
          <!--            <h2 class="banner-text2">Wedding<span>Rings</span></h2>-->
          <!--            <a href="shop.html" class="btn btn-text">Shop Now</a>-->
          <!--          </div>-->
        </figure>
      </div>
    </div>
  </div>
</div>
<div class="spacing"></div>
