<div class="footer-top section-padding">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="widget-item">
          <div class="widget-title">
            <div class="widget-logo"><a routerlink="/" ng-reflect-router-link="/" href="/"><img alt="brand logo"
                  src="assets/theme/img/logo/logo__.png" style="max-width: 250px;"></a></div>
          </div>
          <div class="widget-body">
            <p class="h6 text-justify"> After Serving In The Luxury Domain With Sheer
              Passion In Jewellery Making For Over 35 Years, As KAMARA We Are The 3rd Generation Of Expert Jewellers,
              Fusing The Expertise And Experience Of Our Parent Company Into Absolute Modernity. We Bring You Our
              Extensive Line Of Fine Jewellery With An Innovative Online Jewellery Shopping Experience. </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="row">
          <div class="col-6">
            <div class="widget-item">
              <h6 class="widget-title">Contact Us</h6>
              <div class="widget-body">
                <address class="contact-block">
                  <ul _ngcontent-serverapp-c90="">
                    <li _ngcontent-serverapp-c90=""><i class="pe-7s-mail"></i><a
                        href="mailto:customer.care@kamara.in">customer.care@kamara.in</a>
                    </li>
                    <li _ngcontent-serverapp-c90=""><i class="pe-7s-call"></i><a href="tel:0123456789">8866008860</a>
                    </li>
                  </ul>
                </address>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="widget-item">
              <h6 class="widget-title">Information</h6>
              <div class="widget-body">
                <address class="contact-block">
                  <ul _ngcontent-serverapp-c90="">
                    <li _ngcontent-serverapp-c90=""><a routerlink="privacy-policy/policy"
                        ng-reflect-router-link="privacy-policy/policy" href="/privacy-policy/policy">Privacy Policy</a>
                    </li>
                    <li _ngcontent-serverapp-c90=""><a _ngcontent-serverapp-c90=""
                        routerlink="privacy-policy/terms&amp;condition"
                        ng-reflect-router-link="privacy-policy/terms&amp;condition"
                        href="/privacy-policy/terms&amp;condition">Terms &amp; Conditions</a></li>
                  </ul>
                </address>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-body">
          <div class="widget-body social-link"><a href="javascript:void(0)"><i class="fa fa-facebook"></i></a><a href="javascript:void(0)"><i
                class="fa fa-twitter"></i></a><a href="javascript:void(0)"><i class="fa fa-instagram"></i></a><a href="javascript:void(0)"><i
                class="fa fa-youtube"></i></a></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer-bottom">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="copyright-text text-center">
          <p class="copyright-text"> © 2021 <a href="index.html">Kamara</a>. Made with <i
              class="fa fa-heart text-danger"></i> by <a href="https://www.weybee.com/" target="_blank">Weybee
              Solution</a></p>
        </div>
      </div>
    </div>
  </div>
</div>
