<!-- <h1 class="not--found">Oops!! No product found</h1> -->

<div class="image"> 
    <img src="assets/image/404(1).png" alt="No image Found">
</div>
<h3>No Product Available !!!!</h3>
<!-- 
<section class="error-container">
    <span class="four"><span class="screen-reader-text">4</span></span>
    <span class="zero"><span class="screen-reader-text">0</span></span>
    <span class="four"><span class="screen-reader-text">4</span></span>
</section>
<h1>Page Not Found</h1> -->
