import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { JsLoader } from '@shared/static/js-loader';
import { Image } from '@modals/product.modal';

@Component({
  selector: 'app-product-detail-image',
  templateUrl: './product-detail-image.component.html',
  styleUrls: ['./product-detail-image.component.scss'],
})
export class ProductDetailImageComponent
  implements OnInit, OnChanges, AfterViewInit
{
  isShowModal = false;
  @Input() productImage: Image[];
  @ViewChildren('desktopImgList') desktopImgList: QueryList<any>;
  @ViewChildren('mobileImgList') mobileImgList: QueryList<any>;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngAfterViewInit(): void {
    console.log("after view init aCal this.desktopImgList -->>", this.desktopImgList);

    this.desktopImgList.changes.subscribe((t) => {
      console.log("asdas");

      this.ngForRendered();
    });
    this.mobileImgList.changes.subscribe((t) => {
      this.ngForRendered();
    });

    this.ngForRendered();
  }

  ngForRendered(): void {
    console.log("ngForRendered Call");

    JsLoader.loadProductDetailJs();
    JsLoader.loadProductDetailPopUpJs();
  }

  toggleModal() {
    this.isShowModal = !this.isShowModal;
  }
  @ViewChildren('zoomImage') zoomImages: QueryList<ElementRef>;
  @ViewChildren('lens') lenses: QueryList<ElementRef>;

  onMouseMove(event: MouseEvent, index: number) {
    const zoomElement = this.zoomImages.toArray()[index].nativeElement;
    const lensElement = this.lenses.toArray()[index].nativeElement;

    const imageElement = event.currentTarget as HTMLElement;
    const rect = imageElement.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Position the lens
    const lensSize = lensElement.offsetWidth / 2;
    lensElement.style.left = `${x - lensSize}px`;
    lensElement.style.top = `${y - lensSize}px`;

    // Calculate the background position for the zoomed image
    const xPercent = (x / rect.width) * 100;
    const yPercent = (y / rect.height) * 100;
    zoomElement.style.backgroundPosition = `${xPercent}% ${yPercent}%`;

    // Display the zoomed image and lens
    zoomElement.style.display = 'block';
    lensElement.style.display = 'block';
  }

  onMouseLeave(index: number) {
    const zoomElement = this.zoomImages.toArray()[index].nativeElement;
    const lensElement = this.lenses.toArray()[index].nativeElement;

    // Hide the zoomed image and lens
    zoomElement.style.display = 'none';
    lensElement.style.display = 'none';
  }
}
