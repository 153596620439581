<app-breadcrumb></app-breadcrumb>
<div class="cart-main-wrapper section-padding">
  <div class="container">
    <div *ngIf="!isLoading && cart" class="section-bg-color">
      <!-- card component -->
      <div class="row mobile-card d-lg-none" *ngIf="cart?.items?.length > 0">
        <div class="col-12" *ngFor="let item of cart.items; let i = index">
          <div
            style="
              height: auto;
              width: 100%;
              border: 1px solid #ebebeb;
              margin-bottom: 15px;
            "
          >
            <div class="row no-gutters">
              <div
                [routerLink]="[RouteConfig.productDetail, item.product.url_key]"
                class="col-4"
                style="
                  justify-content: center;
                  align-items: center;
                  display: flex;
                  margin: 20px 0;
                "
              >
                <img
                  [src]="item.product.base_image.small_image_url"
                  alt="Product"
                  class="img-fluid"
                  style="max-width: 60px; height: auto"
                />
              </div>
              <div class="col-8" style="margin-top: 10px">
                <div class="row no-gutters">
                  <div
                    class="col-10"
                    [routerLink]="[
                      RouteConfig.productDetail,
                      item.product.url_key
                    ]"
                  >
                    <div style="font-size: 12px; color: black">
                      {{ item.name | trim: 50 }}
                    </div>
                    <span
                      style="font-size: 12px; color: black; font-weight: bold"
                      >{{ item.formated_price }}</span
                    >
                  </div>
                  <div class="col-2">
                    <a
                      (click)="removeProductFromCart(item.id)"
                      style="color: black"
                    >
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters" style="margin-bottom: 5px">
              <div class="col-1"></div>
              <div
                class="col-10"
                style="height: 0.5px; background-color: #ebebeb"
              ></div>
            </div>
            <div
              class="row no-gutters"
              style="margin-bottom: 10px; margin-left: 15px; margin-right: 15px"
            >
              <div class="col-6">
                <div
                  class="pro-qty"
                  style="
                    width: 90px;
                    height: 25px;

                    padding: 0 0px;
                    float: left;
                  "
                >
                  <span
                    style="
                      width: 15px;

                      float: left;
                      line-height: 38px;
                      cursor: pointer;
                      text-align: center;
                      font-size: 22px;
                      font-weight: 400;
                      color: black;
                      height: 25px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                    (click)="updateQuantity(i, -1)"
                    class="dec qtybtn {{
                      item.product.show_quantity_changer ? '' : 'C_NA'
                    }}"
                    >-</span
                  >
                  <input
                    style="
                      width: 28px;
                      float: left;
                      border: none;
                      height: 25px;
                      line-height: 34px;
                      padding: 0;
                      text-align: center;
                      background-color: transparent;
                    "
                    [value]="item.quantity"
                    type="text"
                  />
                  <span
                    style="
                      width: 15px;

                      float: left;
                      line-height: 38px;
                      cursor: pointer;
                      text-align: center;
                      font-size: 22px;
                      font-weight: 400;
                      color: black;
                      height: 25px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                    (click)="updateQuantity(i, 1)"
                    class="inc qtybtn  {{
                      item.product.show_quantity_changer ? '' : 'C_NA'
                    }}"
                    >+</span
                  >
                </div>
              </div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              >
                <span
                  style="font-size: 12px; color: black; font-weight: bold"
                  >{{ item.formated_total }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row d-none d-lg-block">
        <div class="col-lg-12">
          <!-- Cart Table Area -->
          <div class="cart-table table-responsive">
            <table class="table table-bordered" style="min-width: 720px">
              <thead>
                <tr>
                  <!--<th class="pro-thumbnail">Thumbnail</th>-->
                  <th class="pro-title">Product</th>
                  <th class="pro-price">Price</th>
                  <th class="pro-quantity">Quantity</th>
                  <th class="pro-subtotal">Total</th>
                  <th class="pro-remove">Remove</th>
                </tr>
              </thead>
              <tbody *ngIf="cart?.items?.length > 0">
                <tr *ngFor="let item of cart.items; let i = index">
                  <!--                <td class="pro-thumbnail"><a href="javascript:void(0)">-->
                  <!--                  <img [src]="item.product.base_image.small_image_url" alt="Product" class="img-fluid"></a>-->
                  <!--                </td>-->
                  <td
                    [routerLink]="[
                      RouteConfig.productDetail,
                      item.product.url_key
                    ]"
                    class="pro-title w-100 C_P"
                    style="text-align: left"
                  >
                    <div
                      class="d-flex justify-content-between align-items-start"
                    >
                      <div class="w-25">
                        <img
                          [src]="item.product.base_image.small_image_url"
                          alt="Product"
                          class="img-fluid"
                        />
                      </div>
                      <div class="w-75">
                        <p style="white-space: normal">
                          {{ item.name | trim: 150 }}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td class="pro-price">
                    <span>{{ item.formated_price }}</span>
                  </td>
                  <td class="pro-quantity">
                    <div class="pro-qty">
                      <span
                        (click)="updateQuantity(i, -1)"
                        class="dec qtybtn {{
                          item.product.show_quantity_changer ? '' : 'C_NA'
                        }}"
                        >-</span
                      >
                      <input
                        [value]="item.quantity"
                        type="text"
                        style="width: 25px"
                      />
                      <span
                        (click)="updateQuantity(i, 1)"
                        class="inc qtybtn  {{
                          item.product.show_quantity_changer ? '' : 'C_NA'
                        }}"
                        >+</span
                      >
                    </div>
                  </td>
                  <td class="pro-subtotal">
                    <span>{{ item.formated_total }}</span>
                  </td>
                  <td class="pro-remove">
                    <a (click)="removeProductFromCart(item.id)" class="C_P">
                      <i class="fa fa-trash-o"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Cart Update Option -->
          <div
            class="cart-update-option d-block d-md-flex justify-content-between"
          >
            <div class="apply-coupon-wrapper d-none">
              <form action="#" class="d-block d-md-flex" method="post">
                <input
                  placeholder="Enter Your Coupon Code"
                  required=""
                  type="text"
                />
                <button class="btn btn-sqr">Apply Coupon</button>
              </form>
            </div>
            <div class="cart-update ml-auto">
              <button
                (click)="handleClearCartClick()"
                class="btn btn-sqr mr-2"
                *ngIf="cart"
              >
                Clear Cart
              </button>
              <button class="btn btn-sqr d-none">Update Cart</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 ml-auto">
          <!-- Cart Calculation Area -->
          <div class="cart-calculator-wrapper">
            <div class="cart-calculate-items">
              <h6>Cart Totals</h6>
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Sub Total</td>
                      <td>{{ cart?.formated_sub_total }}</td>
                    </tr>
                    <tr>
                      <td>Shipping</td>
                      <td>{{ cart?.formated_tax_total }}</td>
                    </tr>
                    <tr class="total">
                      <td>Total</td>
                      <td class="total-amount">
                        {{ cart?.formated_grand_total }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <a [routerLink]="RouteConfig.checkout" class="btn btn-sqr d-block"
              >Proceed Checkout</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="container text-center text-dark py-5"
      *ngIf="!isLoading && !cart"
    >
      <i class="fa fa-shopping-cart fa-5x mb-2"></i>
      <h3 class="text-secondary mb-2">Your Cart Is Currently Empty</h3>
      <a [routerLink]="RouteConfig.home" class="text-primary"
        ><i class="fa fa-shopping-cart d-none"></i> Go to shopping</a
      >
    </div>
  </div>
</div>
