import { Injectable } from '@angular/core';
import { Urls } from '../../shared/config/url';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MegaMenuService {

  baseCategoryUrl = Urls.categories;

  constructor(private http: HttpClient, private router:Router) {
  }


  getCategoryTree(): Promise<any> {
    const url = `${Urls.baseUrl}category-tree`;
    return new Promise((resolve, reject) => {
      this.http.get<any>(url).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => reject(err)
      );
    });
  }

  gotoLink(path: any) {
    if (path) {
      try {
        console.log(path);

        const isValidUrl = (url: string) => {
          try {
            return new URL(url).protocol === 'https:';
          } catch {
            return false;
          }
        };

        if (path.startsWith('https://') && !path.includes('xyraa.com') && isValidUrl(path)) {
          window.open(path, '_blank');
        } else if (!path.startsWith('http') || path.includes('xyraa.com')) {
          this.gotoUrl( new URL(path).pathname);
        } else {
          console.error('Invalid URL:', path);
        }
      } catch (err) {
        console.error('Error processing URL:', err);
      }
    }
  }
  gotoUrl(link: any) {
    this.router.navigateByUrl(link)
  }
    // getLink(path:any){

    // }
}
