<app-breadcrumb></app-breadcrumb>
<div class="container mt-4">
    <div class="d-flex flex-column justify-content-between">
        <div class="privacyPolicy">
            <div>
                <h2>Privacy Policy</h2>
            </div>
            <div class="text-justify">
                <p>
                    These terms and conditions govern your use of this website; by using this
                    website, you accept these terms and conditions in full. If you disagree with these terms and
                    conditions or any part of these terms and conditions, you must not use this website.
                </p>
                <p>&nbsp;</p>
                <p>
                    [You must be at least [18] years of age to use this website. By using this website [and by agreeing
                    to these terms and conditions] you warrant and represent that you are at least [18] years of age.]
                    [This website uses cookies. By using this website and agreeing to these terms and conditions, you
                    consent to our <span class="name">{{siteName}}</span> ‘s use of cookies in accordance with the terms of <span class="name">{{siteName}}</span>
                </p>
            </div>
            <div>&nbsp;</div>
        </div>
        <div class="privacyPolicy">
            <div>
                <h2>License to use website</h2>
            </div>
            <div class="text-justify">
                <p>
                    Unless otherwise stated, <span class="name">{{siteName}}</span> its licensors own the intellectual property rights in the
                    website and material on the website. Subject to the license below, all these intellectual property
                    rights are reserved.
                </p>
                <p>&nbsp;</p>
                <p>
                    You may view, download for caching purposes only, and print pages from the website for your own
                    personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.
                </p>
            </div>
            <div>&nbsp;</div>
        </div>
        <div class="privacyPolicy">
            <div>
                <h2>You must not:</h2>
            </div>
            <div class="text-justify">
                <ul>
                    <li>Republish Material From This Website (Including Republication On Another Website)</li>
                    <li>Sell, Rent Or Sub-License Material From The Website</li>
                    <li>Show Any Material From The Website In Public</li>
                    <li>Reproduce, Duplicate, Copy Or Otherwise Exploit Material On This Website For A Commercial Purpose</li>
                    <li>Edit Or Otherwise Modify Any Material On The Website</li>
                    <li>Redistribute Material From This Website [Except For Content Specifically And Expressly Made Available For Redistribution].</li>
                    <li>Where Content Is Specifically Made Available For Redistribution, It May Only Be Redistributed [Within Your Organisation]</li>
                </ul>
            </div>
            <div>&nbsp;</div>
        </div>
        <div class="privacyPolicy">
            <div>
                <h2>Acceptable use</h2>
            </div>
            <div class="text-justify">
                <p>
                    You must not use this website in any way that causes, or may cause, damage to the website or
                    impairment of the availability or accessibility of the website; or in any way which is unlawful,
                    illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful
                    purpose or activity.
                </p>
                <p>&nbsp;</p>
                <p>
                    You must not use this website to copy, store, host, transmit, send, use, publish or distribute any
                    material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm,
                    keystroke logger, rootkit or other malicious computer software.
                </p>
                <p>&nbsp;</p>
                <p>
                    You must not conduct any systematic or automated data collection activities (including without
                    limitation scraping, data mining, data extraction and data harvesting) on or in relation to this
                    website without <span class="name">{{siteName}}</span> express written consent.
                </p>
                <p>&nbsp;</p>
                <p>
                    [You must not use this website to transmit or send unsolicited commercial communications.] [You must
                    not use this website for any purposes related to marketing without <span class="name">{{siteName}}</span> express written
                    consent.]
                </p>
            </div>
            <div>&nbsp;</div>
        </div>
        <div class="privacyPolicy">
            <div>
                <h2>Restricted access</h2>
            </div>
            <div class="text-justify">
                <p>
                    [Access to certain areas of this website is restricted.] <span class="name">{{siteName}}</span> reserves the right to restrict
                    access to [other] areas of this website, or indeed this entire website, at [NAME’S] discretion.
                </p>
                <p>&nbsp;</p>
                <p>
                    If <span class="name">{{siteName}}</span> provides you with a user ID and password to enable you to access restricted areas of
                    this website or other content or services, you must ensure that the user ID and password are kept
                    confidential.
                </p>
                <p>&nbsp;</p>
                <p>
                    <span class="name">{{siteName}}</span> may disable your user ID and password in sole discretion without notice or explanation.]
                </p>
            </div>
            <div>&nbsp;</div>
        </div>
        <div class="privacyPolicy">
            <div>
                <h2>Breaches of these terms and conditions</h2>
            </div>
            <div class="text-justify">
                <p>
                    Without prejudice to <span class="name">{{siteName}}</span> other rights under these terms and conditions, if you breach these
                    terms and conditions in any way, <span class="name">{{siteName}}</span> may take such action as deems appropriate to deal with
                    the breach, including suspending your access to the website, prohibiting you from accessing the
                    website, blocking computers using your IP address from accessing the website, contacting your
                    internet service provider to request that they block your access to the website and/or bringing
                    court proceedings against you.
                </p>
            </div>
            <div>&nbsp;</div>
        </div>
    </div>
</div>