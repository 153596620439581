<div class="container">
    <div>
        <div>
            <p>&nbsp;</p>
            <h4>Contact Us</h4>
            <p>&nbsp;</p>
            <p> For Over 35 Years And Counting, We've Ensured Our Seamless Service In The
                Luxury Domain With Sheer Passion In Jewellery Making. At KAMARA We Are The 3rd Generation Of Expert
                Jewellers Fusing Our Ultimate Expertise and Experience Into ABSOLUTE MODERNITY. Blazing Our Path Into
                The Age Of Individualism, KAMARA'S Vision Of Casual Luxury Is Effortlessly Translated Into Signature
                Catalogs That Fills The Void Of Ever-Evolving Desires And Needs Of A Modern Wardrobe. An Unique Blend Of
                Nature's Most Captivating Elements, Timeless Features, Endless Creativity And Masterful Craftsmanship,
                Brings Our Extensive Line Of Fine Jewellery To Life. Our Mesmerizing Designs Are Wearable Day In And Day
                Out Which Adds Pure Brilliance To Your Wardrobe By Elevating It To New Levels. </p>
            <p>&nbsp;</p>
            <hr>
            <p><i class="fa fa-envelope-o"></i> E-mail:
                customer.care@kamara.in, corporate.sales@kamara.in </p>
            <hr>
            <p><i class="fa fa-phone"></i> +91 886 600 8860 </p>
        </div>
    </div>
</div>
<div class="spacing"></div>
