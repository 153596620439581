<pagination-template #p="paginationApi" (pageChange)="pageChange.emit($event)" [id]="id" [maxSize]="maxSize"
>
  <div class="paginatoin-area text-center">
    <ul class="pagination-box">
      <li *ngIf="!p.isFirstPage()" [class.C_NA]="p.isFirstPage()"><a (click)="p.previous()" class="previous C_P"><i
        class="pe-7s-angle-left"></i></a>
      </li>
      <li *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value" class="C_P">{{ page.label }}</a>
        <a *ngIf="p.getCurrent() === page.value" class="C_NA">{{ page.label }}</a>
      </li>
      <li [class.C_NA]="p.isLastPage()"><a (click)="p.next()" *ngIf="!p.isLastPage()" class="next C_P"><i
        class="pe-7s-angle-right"></i></a></li>
    </ul>
  </div>
  <!--  <div [class.disabled]="p.isFirstPage()" class="pagination-previous">-->
  <!--    <a (click)="p.previous()" *ngIf="!p.isFirstPage()"> < </a>-->
  <!--  </div>-->

  <!--  <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">-->
  <!--    <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">-->
  <!--      <span>{{ page.label }}</span>-->
  <!--    </a>-->
  <!--    <div *ngIf="p.getCurrent() === page.value">-->
  <!--      <span>{{ page.label }}</span>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <div [class.disabled]="p.isLastPage()" class="pagination-next">-->
  <!--    <a (click)="p.next()" *ngIf="!p.isLastPage()"> > </a>-->
  <!--  </div>-->

</pagination-template>

<!--<div class="paginatoin-area text-center">-->
<!--  <ul class="pagination-box">-->
<!--    <li><a class="previous" href="javascript:void(0)"><i class="pe-7s-angle-left"></i></a></li>-->
<!--    <li class="active"><a href="javascript:void(0)">1</a></li>-->
<!--    <li><a href="javascript:void(0)">2</a></li>-->
<!--    <li><a href="javascript:void(0)">3</a></li>-->
<!--    <li><a class="next" href="javascript:void(0)"><i class="pe-7s-angle-right"></i></a></li>-->
<!--  </ul>-->
<!--</div>-->
