<app-breadcrumb></app-breadcrumb>
<div class="cart-main-wrapper section-padding">
  <div class="container">
    <div *ngIf="!isLoading" class="section-bg-color">
      <div class="row">
        <div class="col-lg-12">
          <!-- Cart Table Area -->
          <div class="cart-table table-responsive">
            <table class="table table-bordered" style="min-width: 540px">
              <thead>
              <tr>
                <!--                <th class="pro-thumbnail">Thumbnail</th>-->
                <th class="pro-title">Product</th>
                <th class="pro-price">Price</th>
                <th class="pro-quantity">Quantity</th>
                <th class="pro-subtotal">Total</th>
              </tr>
              </thead>
              <tbody *ngIf="order?.items?.length>0">
              <tr *ngFor="let item of order.items;let i=index;">
                <!--                <td class="pro-thumbnail"><a href="javascript:void(0)">-->
                <!--                  <img [src]="item.product.base_image.small_image_url" alt="Product" class="img-fluid"></a>-->
                <!--                </td>-->
                <td [routerLink]="[RouteConfig.productDetail,item.product.url_key]" class="w-100 C_P"
                    style="text-align: left">
                  <div class="d-flex justify-content-between align-items-start">
                    <div class="w-25">
                      <img [src]="item.product.base_image.small_image_url" alt="Product" class="img-fluid">
                    </div>
                    <div class="w-75 pl-1">
                      <p style="white-space: normal">
                        {{item.product.name|trim:50}}
                      </p>
                    </div>
                  </div>
                </td>
                <td class="pro-price">
                  <span>{{item.formated_price}}</span>
                </td>
                <td class="pro-quantity">
                  <span>{{item.qty_ordered}}</span>
                </td>
                <td class="pro-subtotal">
                  <span>{{item.formated_total}}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-7">
          <div class="row mt-5">
            <div class="col-md-12">
              <address>
              <h4 style="display: inline; color: #969696;">Order Status : </h4><h4  style="color: black; display: inline;">{{order?.status_label == 'Closed' ? 'Return order request submitted' : order?.status_label}}</h4>
              <br>
              </address>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7">
          <div class="row mt-5">
            <div class="col-md-6">
              <h6>Billing Address</h6>
              <app-address [address]="order.billing_address"></app-address>
            </div>
            <div class="col-md-6">
              <h6>Shipping Address</h6>
              <app-address [address]="order.shipping_address"></app-address>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <!-- Cart Calculation Area -->
          <div class="cart-calculator-wrapper">
            <div class="cart-calculate-items">
              <h6>Cart Totals</h6>
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                  <tr>
                    <td>Sub Total</td>
                    <td>{{order?.formated_sub_total}}</td>
                  </tr>
                  <tr class="d-none">
                    <td>Shipping Amount</td>
                    <td>{{order?.formated_shipping_amount}}</td>
                  </tr>
                  <tr class="total">
                    <td>Total</td>
                    <td class="total-amount">{{order?.formated_grand_total}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button *ngIf="order.status == 'pending'" (click)="cancelOrder()" class="btn btn-sqr">
        Cancel Order
      </button>
      <button *ngIf="order.status == 'completed'" (click)="returnOrder()" class="btn btn-sqr">
        Return Order
      </button>
    </div>
  </div>
</div>
