<app-breadcrumb></app-breadcrumb>
<div class="shop-main-wrapper section-padding">
  <div class="container-fluid">
    <div class="row mb-5" *ngIf="filtersWithCategory">
      <div class="mx-auto" *ngIf="filtersWithCategory?.image">
        <img
          [src]="filtersWithCategory?.image"
          alt="filtercopy image"
          class="img-fluid"
          style="max-height: 150px"
        />
      </div>
      <div class="col-12 my-2" *ngIf="filtersWithCategory?.description">
        <div
          class="text-center"
          [innerHTML]="filtersWithCategory?.description"
        ></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <!-- sidebar area start -->
      <app-filter
        (filterChanged)="handleFilterChange($event)"
        class="col-lg-3 col-xl-2 order-2 order-lg-1"
      ></app-filter>
      <!-- sidebar area end -->

      <!-- shop main wrapper start -->
      <div class="col-lg-9 col-xl-10 order-1 order-lg-2">
        <div class="shop-product-wrapper">
          <!-- shop product top wrap start -->
          <div class="shop-top-bar">
            <div class="row align-items-center">
              <!--              <div class="col-lg-7 col-md-6 order-2 order-md-1 d-md-none">-->
              <!--                <div class="top-bar-left">-->
              <!--                                    <button class="border btn btn-primary p-2">-->
              <!--                                      Clear Filter-->
              <!--                                    </button>-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="col-lg-7 col-md-6 order-2 order-md-1">
                <div class="top-bar-left">
                  <button
                    class="border btn btn-primary py-2 px-4 d-md-none"
                    (click)="eventBusEmitService.showMobileFilter()"
                  >
                    Filter
                  </button>
                  <div class="product-view-mode d-none d-md-block">
                    <a
                      class="active"
                      data-original-title="Grid View"
                      data-target="grid-view"
                      data-toggle="tooltip"
                      href="javascript:void(0)"
                      title=""
                      ><i class="fa fa-th"></i
                    ></a>
                    <a
                      class=""
                      data-original-title="List View"
                      data-target="list-view"
                      data-toggle="tooltip"
                      href="javascript:void(0)"
                      title=""
                      ><i class="fa fa-list"></i
                    ></a>
                  </div>
                  <div *ngIf="pagination.total" class="product-amount">
                    <p>{{ pagination.total }} Designs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- shop product top wrap start -->

          <!-- product item list wrapper start -->
          <div
            *ngIf="!isLoading"
            class="shop-product-wrap row mbn-30 grid-view"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScroll($event)"
          >
            <app-product-list-one-product
              *ngFor="let product of productList"
              [product]="product"
              class="col-md-4 col-sm-6 col-6"
            ></app-product-list-one-product>
          </div>
          <div
            class="d-flex w-100 justify-content-center py-5"
            *ngIf="infiniteScrollLoading"
          >
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <!-- product item list wrapper end -->
          <!--          <app-pagination (pageChange)="pageChange($event)" id="aaa"></app-pagination>-->
          <!--          <pagination-controls (pageChange)="pageChange($event)" maxSize="5" nextLabel=">"-->
          <!--                               previousLabel="<"></pagination-controls>-->
        </div>
      </div>
      <!-- shop main wrapper end -->
    </div>
  </div>
</div>
