import { Component, OnInit, ViewChild } from '@angular/core';
import { EventBusEmitService } from '@shared/service/event-bus-emit.service';
import { CartService } from '@services/cart.service';
import { Cart } from '@modals/cart.modal';
import { CheckoutAddressComponent } from '@pages/checkout-address/checkout-address.component';
import { CheckoutService } from '@services/checkout.service';
import { RouteConfig } from '@shared/config/route-config';
import { Router } from '@angular/router';

declare var Razorpay: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  @ViewChild('checkoutAddressComponent')
  checkoutAddressComponent: CheckoutAddressComponent;
  paymentId: string;
  error: string;
  shipping_method: any;
  paymentDetail: any;

  RouteConfig = RouteConfig;

  isLoading = true;
  cart: Cart;
  selectedAddress;

  constructor(
    private eventBusEmitService: EventBusEmitService,
    private cartService: CartService,
    private checkoutService: CheckoutService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.eventBusEmitService.hideMiniCartModal();
    await this.loadCart();
  }

  async handleSaveOrder(): Promise<void> {
    const res = this.checkoutAddressComponent.getAddressData();
    if (!res.isValid) {
      return;
    }
    this.selectedAddress = res.address;
    await this.placeOrder();
  }

  async saveOrder(): Promise<void> {
    let res = await this.checkoutService.savePaymentMethodToCard();
    console.log(res);
    if (res && !res.error) {
      res = await this.checkoutService.saveOrder();
      if (res?.success && res?.order?.id) {
        this.router
          .navigate([RouteConfig.orderDetail, res?.order?.id])
          .then(() => {
            this.eventBusEmitService.reloadCartCount();
            this.eventBusEmitService.hideLoading();
            this.eventBusEmitService.showMessage({
              msg: 'Your Order Placed Successfully',
            });
          });
      }
    }
  }

  options = {
    key: '',
    amount: '',
    name: '',
    description: '',
    image: '',
    order_id: '',
    handler: async (response) => {
      let res = await this.checkoutService.razorpayPayment({
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_order_id: response.razorpay_order_id,
        razorpay_signature: response.razorpay_signature,
      });
      if (res === 200) {
        this.saveOrder();
      }
    },
    prefill: {
      name: '',
      email: '',
      contact: '',
    },
    notes: {
      address: '',
    },
    theme: {
      color: '#b89975',
    },
  };

  async openRazorPay(details): Promise<void> {
    this.paymentId = '';
    this.error = '';
    // this.orderService.createOrder(this.form).subscribe(
    //   (data) => {
    this.options.key = details.api_key;
    this.options.order_id = details.order_id;
    this.options.amount = details.amount; //paise
    this.options.prefill.name = this.cart.billing_address.name;
    this.options.prefill.email = this.cart.billing_address.email;
    this.options.prefill.contact = this.cart.billing_address.phone;
    this.options.name =
      this.cart.customer_first_name + ' ' + this.cart.customer_last_name;
    var rzp1 = new Razorpay(this.options);
    rzp1.open();

    rzp1.on('payment.failed', function (response) {
      // Todo - store this information in the server
      alert(response.error.description);
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
      this.error = response.error.reason;
    });
    //   },
    //   (err) => {
    //     this.error = err.error.message;
    //   }
    // );
  }

  async placeOrder(): Promise<void> {
    try {
      this.eventBusEmitService.showLoading();
      let res = await this.checkoutService.saveAddressToCard(
        this.selectedAddress
      );
      if (res && !res.error) {
        res = await this.checkoutService.saveShippingMethodToCard();
        if (res && !res.error) {
          res = await this.checkoutService.createOrderRazorpay(
            this.cart.base_grand_total
          );
          if (res && !res.error) {
            this.openRazorPay(res);
          }
          /*
          res = await this.checkoutService.savePaymentMethodToCard();
          if (res && !res.error) {
            res = await this.checkoutService.saveOrder();
            if (res?.success && res?.order?.id) {
              this.router
                .navigate([RouteConfig.orderDetail, res?.order?.id])
                .then(() => {
                  this.eventBusEmitService.reloadCartCount();
                  this.eventBusEmitService.hideLoading();
                  this.eventBusEmitService.showMessage({
                    msg: 'Your Order Placed Successfully',
                  });
                });
            }
          }
          */
        }
      }
    } catch (e) {
      console.error('::::AAA::::BBB::::', e);
    } finally {
      this.eventBusEmitService.hideLoading();
    }
  }

  private async loadCart(): Promise<void> {
    try {
      this.isLoading = true;
      const res = await this.cartService.getCartDetailForCheckout();
      console.clear();
      this.cart = res.data;
      this.isLoading = false;
    } catch (e) {
      console.error('::::AAA::::BBB::::', e);
    }
  }
}
