<div class="container mt-4">
    <div class="d-flex flex-column justify-content-between">
        <div class="privacyPolicy">
            <div>
                <h2>About KAMARA</h2>
                <p>&nbsp;</p>
            </div>
            <div class="text-justify">
                <p> For Over 35 Years And Counting, We've Ensured Our Seamless Service In
                    The Luxury Domain With Sheer Passion In Jewellery Making. At KAMARA We Are The 3rd Generation Of
                    Expert Jewellers Fusing Our Ultimate Expertise &amp; Experience Into ABSOLUTE MODERNITY. Blazing Our
                    Path Into The Age Of Individualism, KAMARA'S Vision Of Casual Luxury Is Effortlessly Translated Into
                    Signature Catalogs That Fills The Void Of Ever-Evolving Desires And Needs Of A Modern Wardrobe. An
                    Unique Blend Of Nature's Most Captivating Elements, Timeless Features, Endless Creativity And
                    Masterful Craftsmanship, Brings Our Extensive Line Of Fine Jewellery To Life. Our Mesmerizing
                    Designs Are Wearable Day In And Day Out Which Adds Pure Brilliance To Your Wardrobe By Elevating It
                    To New Levels. </p>
                <p>&nbsp;</p>
            </div>
            <div class="privacyPolicy">
                <div>
                    <h2>What Makes KAMARA Unique</h2>
                    <p>&nbsp;</p>
                </div>
                <div class="text-justify">
                    <p> At KAMARA We Offer Direct Benefits To Our Clients While Shopping
                        With Us, All Our Products Are "MADE TO ORDER", Originally Designed And Produced At Our Own
                        Studios And Production Facility. We Excel At Bringing Premium Every-day Jewellery To Life With
                        Narrative Artistry And Deliver It At An Exceptionally Great Value. Our Innovative Model
                        Ultimately Eliminates All Hidden Overhead Costs Involved In Traditional Retail Models Such As
                        Rents, Middle-Man Commissions, Multi-level Transit Costs, Huge Inventory Holdings, Expensive
                        Retailer Markups, ETC. We Deliver Absolute Shopping Experience As Our Online Store Guides Our
                        Clients To Choose A Design And Customize It The Way It Suits Their Taste With Various
                        Personalization Options Like Metal Type, Gemstones, Diamonds, Color, Finish, Length, Etc. </p>
                    <p>&nbsp;</p>
                    <p>WE LOVE YOU!</p>
                    <p>&nbsp;</p>
                    <p>No One Means It Like We Do, Happy Shopping!</p>
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>
    </div>
</div>