<app-breadcrumb></app-breadcrumb>
<!-- checkout main wrapper start -->
<div class="checkout-page-wrapper section-padding">
  <div *ngIf="!isLoading && cart" class="container">
    <div class="row">
      <!-- Checkout Billing Details -->
      <div class="col-lg-4">
        <app-checkout-address #checkoutAddressComponent></app-checkout-address>
      </div>

      <!-- Order Summary Details -->
      <div class="col-lg-8">
        <div class="order-summary-details">
          <h5 class="checkout-title">Your Order Summary</h5>
          <div class="order-summary-content">
            <!-- Order summary mobile -->
            <div class="row d-lg-none" *ngIf="cart?.items?.length > 0">
              <div
                class="col-12"
                *ngFor="let item of cart.items; let i = index"
              >
                <div
                  style="
                    height: auto;
                    width: 100%;
                    border: 1px solid #ebebeb;
                    margin-bottom: 15px;
                  "
                >
                  <div class="row no-gutters">
                    <div
                      [routerLink]="[
                        RouteConfig.productDetail,
                        item.product.url_key
                      ]"
                      class="col-4"
                      style="
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        margin: 20px 0;
                      "
                    >
                      <img
                        [src]="item.product.base_image.small_image_url"
                        alt="Product"
                        class="img-fluid"
                        style="max-width: 60px; height: auto"
                      />
                    </div>
                    <div class="col-8" style="margin-top: 10px">
                      <div class="row no-gutters">
                        <div
                          class="col-10"
                          [routerLink]="[
                            RouteConfig.productDetail,
                            item.product.url_key
                          ]"
                        >
                          <div style="font-size: 12px; color: black">
                            {{ item.name | trim: 50 }}
                          </div>
                          <span
                            style="
                              font-size: 12px;
                              color: black;
                              font-weight: bold;
                            "
                            >{{ item.formated_price }}</span
                          >
                        </div>
                        <div class="col-2"></div>
                      </div>
                    </div>
                  </div>
                  <div class="row no-gutters" style="margin-bottom: 5px">
                    <div class="col-1"></div>
                    <div
                      class="col-10"
                      style="height: 0.5px; background-color: #ebebeb"
                    ></div>
                  </div>
                  <div
                    class="row no-gutters"
                    style="
                      margin-bottom: 10px;
                      margin-left: 15px;
                      margin-right: 15px;
                    "
                  >
                    <div class="col-6">
                      <span
                        style="font-size: 12px; color: black; font-weight: bold"
                        >Quantity : {{ item.quantity }}</span
                      >
                    </div>
                    <div
                      class="col-6"
                      style="display: flex; justify-content: flex-end"
                    >
                      <span
                        style="font-size: 12px; color: black; font-weight: bold"
                        >{{ item.formated_total }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row no-gutters" style="width: 100%; padding: 0 15px">
                <div class="col-12 no-gutters ml-auto">
                  <!-- Cart Calculation Area -->
                  <div class="cart-calculator-wrapper">
                    <div class="cart-calculate-items">
                      <h6>Cart Totals</h6>
                      <div class="table-responsive">
                        <table class="table">
                          <tbody>
                            <tr>
                              <td>Sub Total</td>
                              <td>{{ cart?.formated_sub_total }}</td>
                            </tr>
                            <tr>
                              <td>Shipping</td>
                              <td>{{ cart?.formated_tax_total }}</td>
                            </tr>
                            <tr class="total">
                              <td>Total</td>
                              <td class="total-amount">
                                {{ cart?.formated_grand_total }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Order Summary Table -->
            <div
              class="
                order-summary-table
                table-responsive
                text-center
                d-none d-lg-block
              "
            >
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Product Image</th>
                    <th>Products</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody *ngIf="cart?.items?.length > 0">
                  <tr *ngFor="let item of cart.items">
                    <td>
                      <img
                        [src]="item.product.base_image.small_image_url"
                        class="image-round"
                        alt="product"
                      />
                    </td>
                    <td>
                      <a
                        [routerLink]="[
                          RouteConfig.productDetail,
                          item.product.url_key
                        ]"
                        >{{ item.name }}
                      </a>
                    </td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.formated_total }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3">Sub Total</td>
                    <td>
                      <strong>{{ cart.formated_sub_total }}</strong>
                    </td>
                  </tr>
                  <tr class="d-none">
                    <td colspan="3">Shipping</td>
                    <td class="d-flex justify-content-center">
                      <ul class="shipping-type">
                        <li>
                          <div class="custom-control custom-radio">
                            <input
                              checked
                              class="custom-control-input"
                              id="flatrate"
                              name="shipping"
                              type="radio"
                            />
                            <label class="custom-control-label" for="flatrate"
                              >Flat Rate: $70.00</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="custom-control custom-radio">
                            <input
                              class="custom-control-input"
                              id="freeshipping"
                              name="shipping"
                              type="radio"
                            />
                            <label
                              class="custom-control-label"
                              for="freeshipping"
                              >Free Shipping</label
                            >
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">Total Amount</td>
                    <td>
                      <strong>{{ cart.formated_grand_total }}</strong>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!-- Order Payment Method -->
            <div class="order-payment-method">
              <div class="summary-footer-area">
                <div class="custom-control custom-checkbox mb-20">
                  <input
                    class="custom-control-input"
                    id="terms"
                    required
                    type="checkbox"
                  />
                  <label class="custom-control-label" for="terms"
                    >I have read and agree to the website
                    <a>terms and conditions.</a></label
                  >
                </div>
                <button (click)="handleSaveOrder()" class="btn btn-sqr">
                  Place Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container text-center text-dark py-5" *ngIf="!isLoading && !cart">
    <i class="fa fa-shopping-cart fa-5x mb-2"></i>
    <h3 class="text-secondary mb-2">Your Cart Is Currently Empty</h3>
    <a [routerLink]="RouteConfig.home" class="text-primary"
      ><i class="fa fa-shopping-cart d-none"></i> Go to shopping</a
    >
  </div>
</div>
<!-- checkout main wrapper end -->
